import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  UseGetApplications,
  UseCreateApplication,
  UseDeleteApplication,
  UseUpdateApplication,
  ApplicationInput,
  ApplicationOutput,
  ApplicationId,
  UseExportApplications,
  UseGetApplicationsBasic,
} from '@/api/types/application'
import { PromiseType } from '@/utils/types/PromiseType'
import { OptionalFiltersParam } from '@/composables/types/useFilter'
import { BasicEntity } from './types/misc'

const VERSION = 'v1'
const RESOURCE = 'applications'

function useGetApplications(): UseGetApplications {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetApplications['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetApplicationsBasic(): UseGetApplicationsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateApplication(): UseCreateApplication {
  const axios = useAxios<PromiseType<ReturnType<UseCreateApplication['createApplication']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createApplication(data: ApplicationInput): Promise<ApplicationOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createApplication,
  }
}

function useUpdateApplication(): UseUpdateApplication {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateApplication['updateApplication']>>>({ method: 'PUT' })

  function updateApplication(id: ApplicationId, data: ApplicationOutput): Promise<ApplicationOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateApplication,
  }
}

function useDeleteApplication(): UseDeleteApplication {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteApplication['deleteApplication']>>>({ method: 'DELETE' })

  function deleteApplication(id: ApplicationId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteApplication,
  }
}

function useExportApplications(): UseExportApplications {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportApplications(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportApplications,
  }
}

export {
  useGetApplications,
  useGetApplicationsBasic,
  useCreateApplication,
  useUpdateApplication,
  useDeleteApplication,
  useExportApplications,
}
