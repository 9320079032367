
import { computed, defineComponent, PropType, reactive, ref, watch, watchEffect } from '@vue/composition-api'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isRequired } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateNote, useGetNotes, useUpdateNote } from '@/api/note'

import { SupplierId } from '@/api/types/supplier'
import { NoteInput, NoteOutput } from '@/api/types/note'
import { FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'
import { ContractId } from '@/api/types/contract'
import { AssignmentId } from '@/api/types/assignment'
import { TaskId } from '@/api/types/task'
import { EquipmentId } from '@/api/types/equipment'
import { useGetSuppliers } from '@/api/supplier'
import { useGetAssignments } from '@/api/assignment'
import { useGetDemands } from '@/api/demand'
import { useGetPersons } from '@/api/person'
import { useGetContracts } from '@/api/contract'
import { useGetInventoryItems } from '@/api/inventoryItem'
import { useGetEquipmentList } from '@/api/equipment'
import { useGetTasks } from '@/api/task'
import { useGetWorkingHours } from '@/api/workingHours'
import { useGetTenderings } from '@/api/tendering'
import { useGetApplicants } from '@/api/applicant'
import { useGetApplications } from '@/api/application'

type FormNote = (NoteInput & { entity?: string }) | (NoteOutput & { entity: string })

export default defineComponent({
  name: 'AddEditNoteDialog',
  components: {
    CommonAddEditDialog,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entityName: {
      type: String,
      default: null,
    },
    entityId: {
      type: Number as PropType<SupplierId | ContractId | AssignmentId | TaskId | EquipmentId>,
      default: null,
    },
    noteToEdit: {
      type: Object as PropType<NoteOutput | null>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.noteToEdit))

    const { exec: getNotes, data: notes, isLoading: isLoadingNotes } = useGetNotes()
    getNotes()

    const form = ref<FormNote>({} as FormNote)

    const DEFAULT_FORM_FIELDS = ref<FormField[]>([
      {
        value: 'title',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('administration.notes.form.title') as string)],
      },
      {
        value: 'type',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'content',
        fieldType: FORM_FIELDS_ENUM.TEXTAREA,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('administration.notes.form.content') as string)],
      },
      {
        value: 'parent',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(
          () => notes.value?.filter((note) => note.id !== props.noteToEdit?.id && !note.parent?.id) ?? []
        ),
        isLoading: computed(() => isLoadingNotes.value),
        isRequired: false,
        rules: [],
      },
    ])

    const FORM_FIELDS = ref<FormField[]>([
      ...DEFAULT_FORM_FIELDS.value,
      ...(root.$route.path === '/administration/notes' && !isEditMode
        ? [
            {
              value: 'entity',
              fieldType: FORM_FIELDS_ENUM.DROPDOWN,
              items: computed(() => [
                { value: 'supplier', text: root.$t('administration.notes.entities.supplier') },
                { value: 'assignment', text: root.$t('administration.notes.entities.assignment') },
                { value: 'demand', text: root.$t('administration.notes.entities.demand') },
                { value: 'person', text: root.$t('administration.notes.entities.person') },
                { value: 'contract', text: root.$t('administration.notes.entities.contract') },
                { value: 'workingHours', text: root.$t('administration.notes.entities.workingHours') },
                { value: 'tendering', text: root.$t('administration.notes.entities.tendering') },
                { value: 'applicant', text: root.$t('administration.notes.entities.applicant') },
                { value: 'application', text: root.$t('administration.notes.entities.application') },
                { value: 'inventoryItem', text: root.$t('administration.notes.entities.inventoryItem') },
                { value: 'equipment', text: root.$t('administration.notes.entities.equipment') },
                { value: 'task', text: root.$t('administration.notes.entities.task') },
              ]),
              isRequired: false,
            },
          ]
        : []),
    ] as FormField[])

    const entityEntries = ref<unknown[]>([])

    async function getEntityEntries() {
      switch (form.value.entity) {
        case 'supplier': {
          const { exec, data } = useGetSuppliers()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'assignment': {
          const { exec, data } = useGetAssignments()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'demand': {
          const { exec, data } = useGetDemands()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'person': {
          const { exec, data } = useGetPersons()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'contract': {
          const { exec, data } = useGetContracts()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'workingHours': {
          const { exec, data } = useGetWorkingHours()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'tendering': {
          const { exec, data } = useGetTenderings()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'applicant': {
          const { exec, data } = useGetApplicants()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'application': {
          const { exec, data } = useGetApplications()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'inventoryItem': {
          const { exec, data } = useGetInventoryItems()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'equipment': {
          const { exec, data } = useGetEquipmentList()
          await exec()
          entityEntries.value = data.value
          break
        }
        case 'task': {
          const { exec, data } = useGetTasks()
          await exec()
          entityEntries.value = data.value
          break
        }
      }
    }

    watch(
      () => form.value.entity,
      async (_, oldVal) => {
        FORM_FIELDS.value = FORM_FIELDS.value.filter((field) => field.value !== oldVal)

        if (oldVal) delete form.value[oldVal]

        await getEntityEntries()

        FORM_FIELDS.value.push({
          value: form.value.entity || '',
          fieldType: FORM_FIELDS_ENUM.DROPDOWN,
          items: entityEntries.value,
          isRequired: false,
          rules: [],
        })
      }
    )

    const { createNote, isLoading: isLoadingCreateNote } = useCreateNote()

    async function onAddNote(): Promise<void> {
      try {
        if (isOfType<FormNote>(form.value, 'entity')) {
          delete form.value.entity
        }

        await createNote(form.value as NoteInput)

        close()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const { updateNote, isLoading: isLoadingUpdateNote } = useUpdateNote()

    async function onEditNote(): Promise<void> {
      if (isOfType<NoteOutput>(form.value, 'id')) {
        const updatedForm = mapBasicEntityToIdWithForm<NoteOutput>(form.value)

        try {
          await updateNote(updatedForm.id, updatedForm)
          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditNote = computed(() => isLoadingCreateNote.value || isLoadingUpdateNote.value)
    function close(): void {
      emit('reload-notes')

      emit('close')
    }

    watchEffect(() => {
      if (props.entityName) {
        form.value[props.entityName] = props.entityId
      }
    })
    watch(
      () => props.noteToEdit,
      () => {
        if (props.noteToEdit) {
          form.value = props.noteToEdit as FormNote
        }
      },
      { immediate: true }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditNote,
      },
      listeners: {
        onAddNote,

        onEditNote,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
