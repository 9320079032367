import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  ApplicantOutput,
  ApplicantId,
  ApplicantInput,
  UseGetApplicants,
  UseGetApplicantsBasic,
  UseCreateApplicant,
  UseUpdateApplicant,
  UseDeleteApplicant,
} from '@/api/types/applicant'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'applicants'

function useGetApplicants(): UseGetApplicants {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetApplicants['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetApplicantsBasic(): UseGetApplicantsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateApplicant(): UseCreateApplicant {
  const axios = useAxios<PromiseType<ReturnType<UseCreateApplicant['createApplicant']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createApplicant(data: ApplicantInput): Promise<ApplicantOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createApplicant,
  }
}

function useUpdateApplicant(): UseUpdateApplicant {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateApplicant['updateApplicant']>>>({ method: 'PUT' })

  function updateApplicant(id: ApplicantId, data: ApplicantOutput): Promise<ApplicantOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateApplicant,
  }
}

function useDeleteApplicant(): UseDeleteApplicant {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteApplicant['deleteApplicant']>>>({ method: 'DELETE' })

  function deleteApplicant(id: ApplicantId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteApplicant,
  }
}

export { useGetApplicants, useGetApplicantsBasic, useCreateApplicant, useUpdateApplicant, useDeleteApplicant }
